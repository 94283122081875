import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

///import Menu from './Sections/Menu';

const Header = ({  }) => {
    const [isLoading, setIsLoading] = useState(true);

    ///const location = useLocation();

    useEffect(() => {
        // Simulating loading delay
        const timeout = setTimeout(() => {
        setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    });

    //onLogout
    // const handleLogout = () => {
    //     onLogout();
    // };

    return (
        <>
            {isLoading && (
                <div>
                    {/* loader section */}
                    <div className="container-fluid loader-wrap">
                        <div className="row h-100">
                            <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
                                <img src="https://media.pacxos.com/icon.png" height='60' alt='PACXOS' />
                                <p className="mt-4"><span className="text-secondary">PACXOS</span><br /><strong>Peer 2 Peer</strong></p>
                            </div>
                        </div>
                    </div>
                    {/* loader section ends */}
                </div>

            )}

            <div>
            {/* Sidebar main menu */}
                <div className="sidebar-wrap  sidebar-overlay">
                    {/* Add pushcontent or fullmenu instead overlay */}
                    <div className="closemenu text-muted">Close Menu</div>
                        <div className="sidebar ">
                            {/* user information */}
                            <div className="row my-3">
                            <div className="col-12 profile-sidebar">
                                <div className="clearfix" />
                                {/* <div className="circle small one" />
                                <div className="circle small two" />
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 194.287 141.794" className="menubg">
                                    <defs>
                                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2={1} gradientUnits="objectBoundingBox">
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                        </linearGradient>
                                    </defs>
                                    <path id="menubg" d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z" transform="translate(-503.892 -111.404)" fill="url(#linear-gradient)" />
                                </svg> */}
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="pacxos.svg" alt className="rounded-18" />
                                        </figure>
                                    </div>
                                    <div className="col px-0 align-self-center">
                                        <h5 className="mb-2">Farouk</h5>
                                        <p className="text-muted size-12">Farouk_Tallam <i className="bi bi-patch-check"></i></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {/* user emnu navigation */}
                            <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <NavLink className="nav-link active" aria-current="page" to='/'>
                                        <div className="avatar avatar-40 icon"><i className="bi bi-house-door" /></div>
                                            <div className="col">Dashboard</div>
                                        <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/arena' className="nav-link" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon"><i className="bi bi-flag" /></div>
                                            <div className="col">Arena</div>
                                        <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!" tabIndex={-1}>
                                    <div className="avatar avatar-40 icon"><i className="bi bi-newspaper" /></div>
                                    <div className="col">History</div>
                                    <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!" tabIndex={-1}>
                                    <div className="avatar avatar-40 icon"><i className="bi bi-bell" /></div>
                                    <div className="col">Community</div>
                                    <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#!" tabIndex={-1}>
                                    <div className="avatar avatar-40 icon"><i className="bi bi-newspaper" /></div>
                                    <div className="col">Support</div>
                                    <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href='#!' tabIndex={-1}>
                                        <div className="avatar avatar-40 icon"><i className="bi bi-box-arrow-right" /></div>
                                        <div className="col">Logout</div>
                                        <div className="arrow"><i className="bi bi-chevron-right" /></div>
                                    </a>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                {/* Sidebar main menu ends */}
            </div>
        </>
    );
}

export default Header;