import React, { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';

function Wallet() {
    const [token, setToken] = useState();
    const [account, setAccont] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [matchOn, setMatchMode] = useState(false); 
    const [withdrawalData, setWithdrawalData] = useState({
        accountNo:'',
        amount: '',
        transactionCode:'',
        token: ''
    });
    
    const [withdrawalPosted, setWithdrawalPosted] = useState(false);
    const [sendingWithdrawal, setSendingWithdrawal] = useState(false);
    const [withdrawalError, setWithdrawalError] = useState(null);

    useEffect(() => {
        ///console.log("Wallet call to action..");
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            
            if(!token){
                setToken(tokenJson.token);
            }else{
                setAccont(tokenJson);
                fetchWallet(tokenJson.token);
            }
        } else {
            setToken('');
        }
    }, [token]);

    const handleWithdrawalFormChange = (e) => {
        setWithdrawalData({ ...withdrawalData, [e.target.name]: e.target.value });
        setSendingWithdrawal(false);
        setWithdrawalError(null);
    };
    
    const fetchWallet = async (tokenString) => {
        ///console.log("Wallet data...");
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Escrow?token=${tokenString}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setWallet(data);
            fetchTransactions(tokenString);
            setWithdrawalData({ ...withdrawalData, ['token']: tokenString,['accountNo']: data.accountNo,['transactionCode']: data.accountNo });
            ///setMatchMode(true);
        } catch (error) {
            console.error('Error fetching wallet:', error);
            ///setMatchMode(false);
        }
    };

    const fetchTransactions = async (tokenString) => {
        ///console.log("Wallet transactions...");
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Transactions?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTransactions(data);
        } catch (error) {
            console.error('Error fetching Transactions:', error);
        }
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const postWithdrawal = async (e) => {
      e.preventDefault();
      setSendingWithdrawal(true);
      try {
            const response = await fetch('https://api.pacxos.com/v1/api/Withdrawal', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(withdrawalData)
            });
  
            if (!response.ok) {
                const errorResponse = await response.text();
                setWithdrawalError('Failed. Try again');
                setSendingWithdrawal(false);

                return; // Exit the function if there's an error
            } 

            setWithdrawalPosted(true);
      } catch (error) {
            setWithdrawalError('Withdrawal failed, Contact Support.');
            console.error('Error:', error);
            setSendingWithdrawal(false);
      }
    };

    return (
        <>
            <div className=' mt-5 pt-5'>
                {wallet?
                <>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card bg-theme text-white">
                                <div className="card-body pt-4 px-4 pb-0">
                                    <div className="row justify-content-between gx-0 mx-0 pb-3">
                                        <div className="col-auto text-center">
                                            <button type='button' data-bs-toggle="modal" data-bs-target="#deposit" className="avatar avatar-60 p-1 shadow-none border-danger rounded-15 bg-opac mb-2">
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-download size-22" />
                                                </div>
                                            </button>
                                            <p className="size-10">Top-Up</p>
                                        </div>
                                        
                                        <div className="col-auto text-center">
                                            <button type='button' data-bs-toggle="modal" data-bs-target="#withdrawal" className="avatar avatar-60 p-1 shadow-none border-danger rounded-15 bg-opac mb-2" onClick={() => setWithdrawalPosted(false)} >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-upload size-22" />
                                                </div>
                                            </button>
                                            <p className="size-10">Withdraw</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <div className="avatar avatar-50 p-1 shadow-sm shadow-none rounded-15">
                                                <div className="icons bg-dark text-white rounded-12">
                                                    <i className="bi bi-wallet2" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto align-self-center text-end ps-0">
                                            <p className='mb-1'>Ksh. {wallet.accountBalance}</p>
                                            <p className="size-10 text-secondary mb-0">Balance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                <div className="icons bg-dark text-white rounded-12">
                                                <i className="bi bi-shield-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto align-self-center text-end ps-0">
                                            <p className='mb-1'>Ksh. {wallet.escrowBalance}</p>
                                            <p className="size-10 text-secondary mb-0">Escrow</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <p className='text-center'>Please wait....</p>
                </>
                }

                {transactions&&
                    <ul className='list-group list-group-flush bg-none mb-5'>
                        {transactions.map((transaction) => (
                            <li key={transaction.id} className='list-group-item'>
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 p-1 shadow-sm shadow-success rounded-15">
                                            {transaction.transType===1?
                                                <div className="icons bg-success text-white rounded-12">
                                                    <i className="bi bi-chevron-bar-up" />
                                                </div>
                                            :
                                                <>
                                                    {transaction.transType===2?
                                                        <div className="icons bg-danger text-white rounded-12">
                                                            <i className="bi bi-chevron-bar-down" />
                                                        </div>
                                                    :
                                                        <div className="icons bg-dark text-white rounded-12">
                                                            <i className="bi bi-chevron-bar-down" />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="col d-flex flex-column justify-content-between ps-0">
                                        <p className="text-secondary size-10 mb-0">{transaction.transRef}</p>
                                        <p className='size-12'>Ksh {transaction.transAmt}</p>
                                    </div>
                                    <div className="col align-self-center text-end">
                                        <p className="text-secondary text-muted size-10 mb-0">{formatPeriodTime(transaction.createdDate)}</p>
                                        <p>{formatPeriodDate(transaction.createdDate)}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
                <div className='mb-3 text-center'>
                    <img src="pacxos.svg" height='50px' alt />
                </div>
            </div>

            <div className="modal fade" id="deposit" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <div className='d-flex align-items-center justify-content-between gap-1 mb-3'>
                                <b>Deposit</b>
                                <button className="btn btn-light btn-44 filter-btn" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="bi bi-chevron-bar-down size-22" />
                                </button>
                            </div>
                            {/* <form onSubmit={depositStkPush}>
                                <p className='size-12'>Enter payment number and amount, a prompt will be send to your phone. Enter your pin number and complete transaction</p>
                                <div className="row">
                                    <div className="col-6 pe-0 position-relative align-self-center">
                                        <div className="form-floating mb-3">
                                            <input type="tel" className="form-control" placeholder="Payment Reference" required name='phoneNumber' value={depositData.phoneNumber} onChange={handleDepositFormChange} id="phoneNumber" />
                                            <label htmlFor="phoneNumber" className='w-100'>Payment Number</label>
                                        </div>
                                    </div>
                                    <div className="col-4 ps-1 pe-0 position-relative align-self-center">
                                        <div className="form-floating mb-3">
                                            <input type="number" className="form-control" placeholder="Amount" min='50' required name='amount' value={depositData.amount} onChange={handleDepositFormChange} id="amount" />
                                            <label htmlFor="phoneNumber">Amount</label>
                                        </div>
                                    </div>
                                    <div className="col-2 align-self-center">
                                        {sendingDeposit?
                                            <div className='text-center text-muted'>Please Wait...</div>
                                        :
                                            <button className="btn btn-dark btn-44 filter-btn">
                                                <i className="bi bi-check-lg size-22" />
                                            </button>
                                        }
                                    </div>
                                </div>
                                
                                {depositError && <p className="text-danger my-1">{depositError}</p>}
                            </form>
                            <p className='size-12'>If that fails, Try this;</p> */}
                            <ul>
                                <li>Go to <b>M-Pesa</b>, Select <b>Lipa na M-Pesa</b> <span className='mx-2'>-</span> Select <b>Paybill</b></li>
                                <li>Enter paybill number, <b>4356494</b></li>
                                <li>Enter account number, <b>{wallet.accountNo}</b></li>
                            </ul>
                            
                            <p className='size-12'>Enter Amount and your pin and complete transaction. After recieving the Pacxos confirmation message, Click the button below</p>
                            <button className="btn btn-dark btn-44 filter-btn" onClick={() => fetchWallet(token)} data-bs-dismiss="modal" aria-label="Close">
                                <i className="bi bi-check-lg size-22" /> Refresh Wallet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="withdrawal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <div className='d-flex align-items-center justify-content-between gap-1 mb-3'>
                                <b>Withdrawal</b>
                                <button className="btn btn-light btn-44 filter-btn" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="bi bi-chevron-bar-down size-22" />
                                </button>
                            </div>
                            {withdrawalPosted?
                                <div className='text-center p-5'>
                                    <p>Withdrawal request submitted. You will recieve the amount in a few minutes.</p>
                                    <button className="btn btn-dark btn-44 filter-btn" onClick={() => fetchWallet(token)} data-bs-dismiss="modal" aria-label="Close">
                                        <i className="bi bi-check-lg size-22" /> Refresh Wallet
                                    </button>
                                </div>
                            :
                                <form onSubmit={postWithdrawal}>
                                    <ul>
                                        <li>You will receive the withdrawn amount in your M-Pesa account: {wallet.accountNo}</li>
                                        <li>Enter the amount to withdraw. Withdrawal charges apply.</li>
                                    </ul>
                                    <div className="row">
                                        <div className="col-10 position-relative align-self-center">
                                            <div className="form-floating mb-3">
                                                <input type="number" className="form-control" placeholder="Withdrawal Amount" required name='amount' value={withdrawalData.amount} onChange={handleWithdrawalFormChange} id="wamount" />
                                                <label htmlFor="wamount" className='w-100'>Withdrawal Amount</label>
                                            </div>
                                        </div>
                                        <div className="col-2 align-self-center">
                                            {sendingWithdrawal?
                                                <div className='text-center text-muted'>...</div>
                                            :
                                                <button className="btn btn-dark btn-44 filter-btn">
                                                    <i className="bi bi-check-lg size-22" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    
                                    {withdrawalError && <p className="text-danger my-1">{withdrawalError}</p>}
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wallet;