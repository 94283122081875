import React, { useEffect,useState } from 'react';
import { getAuthDataFromLocalStorage } from "./Data";
import { parseISO, isBefore } from 'date-fns';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
////import 'bootstrap/dist/css/bootstrap.min.css';


import Header from './components/Sections/Header';
import Footer from './components/Sections/Footer';
import Navigation from './components/Sections/Navigation';
import Dashboard from './components/Account/Dashboard';
import Profile from './components/Account/Profile';
import Community from './components/Account/Community';
import Support from './components/Account/Support';
import History from './components/Account/History';
import Live from './components/Account/Live';
import Wallet from './components/Account/Wallet';
import Games from './components/Play/Games';
import Game from './components/Play/Game';
import Join from './components/Play/Join';
import Match from './components/Play/Match';
import End from './components/Play/End';
import Bet from './components/Play/Bet';
import Arena from './components/Arena/Directory';

import Auth from './components/Account/Auth';

import { Route, Routes } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const jsonData = localStorage.getItem('pacxosData');

    if (jsonData) {
      const tokenJson = JSON.parse(jsonData);
      if(tokenJson.expiryDate){
        ///console.log("Session iishe:"+ tokenJson.expiryDate);

        const expiry = parseISO(tokenJson.expiryDate);
        const now = new Date();

        if (isBefore(expiry, now)) {
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
        }
      }else{
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('pacxosData');
    localStorage.removeItem('refarralQrCode');
    setIsLoggedIn(false);
  };

  return (
    <div className="App body-scroll">

      {isLoggedIn ? (
        <div>
          <Header/>
            <div className="main-container container pt-100">
              <Navigation onLogout={handleLogout}/>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile onLogout={handleLogout} />} />
                <Route path="/community" element={<Community />} />
                <Route path="/support" element={<Support />} />
                <Route path="/played" element={<History />} />
                <Route path="/live" element={<Live />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/create" element={<Games />} />
                <Route path="/game/:id" element={<Game />} />
                <Route path="/join" element={<Join />} />
                <Route path="/match/:id" element={<Match />} />
                <Route path="/end/:id" element={<End />} />
                <Route path="/bet" element={<Bet />} />
                <Route path="/arena" element={<Arena />} />
              </Routes>
            </div>
          <Footer/>
        </div>
      ) : (
        <Auth path="/" onLoginSuccess={handleLoginSuccess}/>
      )}
      
    </div>
  );
}

export default App;
