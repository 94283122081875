import React, { useEffect,useState } from 'react';

const Profile= ({ onLogout }) => {
    const [token, setToken] = useState();
    const [name, setName] = useState();
    const [account, setAcc] = useState();

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            setToken(tokenJson.token);
            setName(tokenJson.nickname);
            setAcc(tokenJson.phoneNumber);
        } else {
            setToken('');
        }
    }, []);

    const handleLogout = () => {
        onLogout();
    };

    return (
        <>
            <div>
                <div className="mt-5 pt-5 mb-3">
                   <div className='card mb-4'>
                        <div className='card-body'>
                            <div className='row align-items-center'>
                                <div className='col-4'>
                                    <div className="card-body p-1">
                                        <div className="position-absolute end-0 top-0 bg-warning z-index-1 online-status">
                                        </div>
                                        <figure className="avatar avatar-80 shadow-sm rounded-20">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                            </svg>
                                        </figure>
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <h3>{name}</h3>
                                    <p>{account}</p>
                                </div>
                            </div>
                        </div>
                   </div>
                   <div className='row justify-content-between'>
                        <div class="col-auto">
                            <a href="#!" class="btn btn-default btn-44 shadow-sm">
                                <i class="bi bi-gear"></i>
                            </a>
                        </div>
                        <div class="col-auto">
                            <a href="#!" class="btn btn-default btn-44 shadow-sm">
                                <i class="bi bi-lock"></i>
                            </a>
                        </div>
                        <div class="col-auto">
                            <a href="#!" onClick={handleLogout}  class="btn btn-default btn-44 shadow-sm">
                                <i class="bi bi-box-arrow-right"></i>
                            </a>
                        </div>
                   </div>
                </div>
            </div>
        </>
    );
}

export default Profile;