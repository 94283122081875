import React, { useEffect,useState } from 'react';
import { useNavigate , NavLink } from 'react-router-dom';
import Timer from '../Sections/Timer'
import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';

function Dashboard() {
    const [token, setToken] = useState();
    const [account, setAccont] = useState([]);
    const [matches, setMatches] = useState([]);
    const [stats, setStats] = useState([]);
    const [statsRow, setStatsRow] = useState([]);

    const [bets, setBetArena] = useState([]);
    const [betOn, setBetMode] = useState(false); 
    
    const navigate = useNavigate();

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            
            const matchCodeData = localStorage.getItem('matchCode');

            if(matchCodeData){
                console.log("PLAY: "+matchCodeData);
                navigate('/join');
            }

            if(!token){
                setToken(tokenJson.token);
            }else{
                setAccont(tokenJson);
            
                fetchLiveMatch(tokenJson.token);
                fetchTopMatches(tokenJson.token);
                fetchStats(tokenJson.token);
            }
        } else {
            setToken('');
        }
    }, [token]);
    

    const fetchLiveMatch = async (tokenString) => {
        ///setToken(token);
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/BetArena?token=${tokenString}`);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBetArena(data);

            if (data && data.length > 0) {
                setBetMode(true);
            } else {
                setBetMode(false); // Optional: You might want to set this to false if no matches are available
            }
        } catch (error) {
            console.error('Error fetching live match:', error);
            setBetMode(false);
        }
    };

    const fetchTopMatches = async (tokenString) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Matches?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatches(data);
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    const fetchStats = async (tokenString) => {
        try {
            const response = await fetch(`https://api.pacxos.com/v1/api/Dashboard?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setStats(data);
            setStatsRow(data[0]);
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    function calculateDurationInMinutes(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const durationInMilliseconds = endDate - startDate;
        const durationInMinutes = durationInMilliseconds / (1000 * 60);
        return durationInMinutes;
    }

    function calculateDurationInHHMMSS(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const durationInMilliseconds = endDate - startDate;
    
        const totalSeconds = Math.floor(durationInMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 3600 % 60;
    
        const pad = (num) => String(num).padStart(2, '0');
    
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    function viewMatch(player,moderator,ref) {
        if(player||moderator){
            navigate(`/match/${ref}`);
        }else{
            localStorage.setItem("matchCode", ref);
            navigate('/join');
        }
    }

    return (
        <>
            <div>
                {/* balance */}
                <div className="row my-3 text-center mb-5">
                    <div className="col-12">
                        {statsRow.trophies>10000?
                            <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2 badge-title text-gold" data-bs-toggle="modal" data-bs-target="#badges">
                                <svg fill="#ffb300" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <title>badge</title>
                                    <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                </svg>
                                {statsRow.trophies}
                            </h1>
                        :
                            <>
                                {statsRow.trophies>100?
                                    <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2 badge-title text-silver" data-bs-toggle="modal" data-bs-target="#badges">
                                        <svg fill="#9f9f9f" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <title>badge</title>
                                            <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                        </svg>
                                        {statsRow.trophies}
                                    </h1>
                                :
                                    <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2 badge-title" data-bs-toggle="modal" data-bs-target="#badges">
                                        <svg fill="#000000" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <title>badge</title>
                                            <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                        </svg>
                                        {statsRow.trophies}
                                    </h1>
                                }
                            </>
                        }
                        {/* <p className="text-secondary">TROPHYS</p> */}
                    </div>
                </div>
                <div className="modal fade" id="badges" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content shadow">
                            <div className="modal-body p-2">
                                <div className='d-flex align-items-center justify-content-between gap-1 mb-3'>
                                    <b>BADGE</b>
                                    <button className="btn btn-light btn-44 filter-btn" data-bs-dismiss="modal" aria-label="Close">
                                        <i className="bi bi-chevron-bar-down size-22" />
                                    </button>
                                </div>
                                <div className="row my-3 text-center">
                                    <div className="col-12">
                                    {statsRow.trophies>10000?
                                        <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2 text-gold">
                                            <svg fill="#ffb300" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>badge</title>
                                                <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                            </svg>
                                            {statsRow.trophies}
                                        </h1>
                                    :
                                        <>
                                            {statsRow.trophies>100?
                                                <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2 text-silver" data-bs-toggle="modal" data-bs-target="#badges">
                                                    <svg fill="#9f9f9f" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <title>badge</title>
                                                        <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                                    </svg>
                                                    {statsRow.trophies}
                                                </h1>
                                            :
                                                <h1 className="fw-light size-25 d-flex align-items-center my-0 mx-5 justify-content-center mb-2" data-bs-toggle="modal" data-bs-target="#badges">
                                                    <svg fill="#000000" width="40px" height="40px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                        <title>badge</title>
                                                        <path d="M25.307 12.195c-0.248 0-0.477-0.064-0.688-0.164l-2.271 3.935 2.286 3.961c0.188-0.076 0.391-0.122 0.605-0.122 0.9 0 1.63 0.729 1.63 1.63s-0.729 1.631-1.63 1.631c-0.901 0-1.631-0.668-1.631-1.568 0-0.015 0.009-0.041 0.009-0.041h-4.404l-2.373 4.050c0.465 0.287 0.791 0.777 0.791 1.363 0 0.9-0.73 1.631-1.631 1.631s-1.63-0.73-1.63-1.631c0-0.629 0.367-1.157 0.888-1.43l-2.335-3.983h-4.608c0 0.015 0.008 0.026 0.008 0.041 0 0.9-0.73 1.568-1.63 1.568s-1.63-0.73-1.63-1.631 0.73-1.63 1.63-1.63c0.278 0 0.528 0.088 0.758 0.211l2.338-4.050-2.31-4c-0.236 0.133-0.495 0.229-0.786 0.229-0.9 0-1.63-0.729-1.63-1.63s0.73-1.631 1.63-1.631c0.891 0 1.609 0.716 1.625 1.604h4.604l2.307-3.997c-0.504-0.276-0.86-0.794-0.86-1.41 0.001-0.902 0.731-1.631 1.631-1.631s1.631 0.729 1.631 1.631c0 0.573-0.314 1.054-0.764 1.345l2.346 4.062h4.469c0.016-0.888 0.734-1.604 1.625-1.604 0.9 0 1.631 0.73 1.631 1.631s-0.731 1.63-1.631 1.63zM16 11.584c-2.401 0-4.348 1.946-4.348 4.348s1.947 4.348 4.348 4.348c2.4 0 4.348-1.947 4.348-4.349s-1.948-4.347-4.348-4.347zM16 19.396c-1.914 0-3.465-1.551-3.465-3.465 0-1.913 1.551-3.464 3.465-3.464 1.913 0 3.465 1.551 3.465 3.464 0 1.915-1.552 3.465-3.465 3.465z"></path>
                                                    </svg>
                                                    {statsRow.trophies}
                                                </h1>
                                            }
                                        </>
                                    }
                                        {/* <p className="text-secondary">TROPHYS</p> */}
                                    </div>
                                </div>
                                <p className='text-center size-10'>Play, Win, and Get Rewarded on Pacxos! For every 10 matches you win, earn a special badge. Redeem your badges for amazing prizes in our yearly draw! Start playing and start winning today with Pacxos.</p>
                                <div className='p-3 border rounded'>
                                    <p className='d-flex justify-content-between'>0 - 100  Badges <b>BLACK</b></p>
                                    <hr/>
                                    <p className='d-flex justify-content-between'>101 - 1,000  Badges <b className='text-silver'>SILVER</b></p>
                                    <hr/>
                                    <p className='d-flex justify-content-between'>10,000 - X  Badges <b className='text-gold'>GOLD</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* income expense*/}
                <div className="row mb-4 pt-4">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body p-3">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 rounded-15">
                                            <div className="icons bg-success text-white rounded-12">
                                                <i className="bi bi-award" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center text-end ps-0">
                                        <p className='mb-1'>{statsRow.wins}</p>
                                        <p className="size-10 text-secondary mb-0">Wins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body p-3">
                                <div className="row justify-content-between">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 rounded-15">
                                            <div className="icons bg-danger text-white rounded-12">
                                            <i className="bi bi-slash-circle" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center text-end ps-0">
                                        <p className='mb-1'>{statsRow.loses}</p>
                                        <p className="size-10 text-secondary mb-0">Losses</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="card bg-theme text-white">
                            <div className="card-body pt-4 px-4 pb-0">
                                <div className="row justify-content-between gx-0 mx-0 pb-3">
                                    <div className="col-auto text-center">
                                        <NavLink to='/create' className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2">
                                            <div className="icons bg-success text-white rounded-12 bg-opac">
                                                <i className="bi bi-plus-lg size-22" />
                                            </div>
                                        </NavLink>
                                        <p className="size-10">Create</p>
                                    </div>
                                    <div className="col-auto text-center">
                                        <NavLink to='/join' className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2">
                                            <div className="icons bg-success text-white rounded-12 bg-opac">
                                                <i className="bi bi-play size-22" />
                                            </div>
                                        </NavLink>
                                        <p className="size-10">Play</p>
                                    </div>
                                    <div className="col-auto text-center">
                                        <NavLink to='/live' className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2">
                                        <div className="icons bg-success text-white rounded-12 bg-opac">
                                            <i className="bi bi-record-circle size-22" />
                                        </div>
                                        </NavLink>
                                        <p className="size-10">LIVE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {betOn?
                    <div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className="flex-grow-1">
                                <h6 className="size-10">Bet Arena</h6>
                            </div>
                            <div className="flex-grow-0 align-self-center">
                                <NavLink to={'/bet'} className="small">Find More</NavLink>
                            </div>
                        </div>

                        <Carousel interval={5000} controls={true} indicators={true}>
                            {bets.slice(0, 5).map((bet) => (
                                <Carousel.Item key={bet.id}>
                                    <div className="card p-3 mb-4 shadow-none">
                                        <div className="row align-items-center" onClick={()=>viewMatch(bet.IsPlayer,bet.IsModerator,bet.matchRef)}>
                                            <div className="col-auto">
                                                <div className="avatar avatar-50 p-1 shadow-none shadow-dark rounded-15">
                                                    <div className="icons border border-danger text-danger rounded-12">
                                                        <i className="bi bi-bullseye" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto ps-0">
                                                <p className="text-dark size-12 fw-bold mb-0">{bet.matchRef}</p>
                                                <p className='mb-0 size-12 text-secondary fw-bold'>{bet.ModeratorName}</p>
                                            </div>
                                            <div className="col align-self-center text-end">
                                                <small><em><b>Stake</b></em></small>
                                                <p>Ksh {bet.stakeAmt}</p>
                                            </div>
                                            <div className="col align-self-center text-end">
                                                <small><em><b>Win</b></em></small>
                                                <p>Ksh {bet.winAmt}</p>
                                            </div>
                                        </div>
                                        <hr className='my-2'/>
                                        <small><em>{bet.matchDescription}</em></small>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                :
                <></>}

                {/* {matchOn?
                    <NavLink to={`/match/${match.matchRef}`} className='card mb-4'>
                        <div className="card-body py-3 px-4">
                            <div className='position-absolute end-0 top-0 mt-2 z-index-1'><small className='tag tag-small bg-primary text-white me-2 size-6'>{match.matchRole}</small></div>
                            <p className="size-10 mb-1"><span className='text-danger'><i className="bi bi-record-circle size-16 me-1" />{match.matchStatusTitle}</span> | {match.players} Players</p>
                            <div className='flex-grow-1 d-flex flex-column justify-content-between'>
                                <p className="mb-2">{match.matchRef}</p>
                                <small className='d-flex gap-1'>Stake:<b className="mx-1">Ksh. {match.stakeAmt}</b> <b className="mx-1">|</b>Moderator:<b className="mx-1">{match.moderatorName}</b></small>
                            </div>
                        </div>
                    </NavLink>
                :
                    <div></div>
                } */}

                <div>
                    <div className="row mb-0">
                        <div className="col">
                            <h6 className="size-10">Played</h6>
                        </div>
                        <div className="col-auto align-self-center">
                            <NavLink to={'/played'} className="small">View all</NavLink>
                        </div>
                    </div>
                </div>

                <ul className='list-group list-group-flush bg-none mb-5'>
                    {matches.slice(0, 5).map((match) => (
                        <li key={match.id} className='list-group-item'>
                            <NavLink to={`/match/${match.matchRef}`} className="row align-items-center">
                                <div className="col-auto">
                                    {match.MatchStatusTitle==='LIVE'?
                                        <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                            <div className="icons border border-danger text-danger rounded-12">
                                                <i className="bi bi-stopwatch" />
                                            </div>
                                        </div>
                                    :
                                        <div>
                                            {match.IsModerator==='MODERATOR'?
                                                <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                    {match.IsWinner==='WINNER'?
                                                        <div className="icons bg-success text-white rounded-12">
                                                            <i className="bi bi-shield-shaded" />
                                                        </div>
                                                    :
                                                        <>
                                                            {match.IsPlayer==='PLAYER'?
                                                                <div className="icons bg-danger text-white rounded-12">
                                                                    <i className="bi bi-shield-shaded" />
                                                                </div>
                                                            :
                                                                <div className="icons bg-dark text-white rounded-12">
                                                                    <i className="bi bi-shield-shaded" />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            :
                                                <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                    {match.IsWinner==='WINNER'?
                                                        <div className="icons bg-success text-white rounded-12">
                                                            <i className="bi bi-award" />
                                                        </div>
                                                    :
                                                        <div className="icons bg-danger text-white rounded-12">
                                                            <i className="bi bi-slash-circle" />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="col-auto ps-0">
                                    <p className="text-secondary size-10 mb-1">
                                        {match.MatchStatusTitle==='LIVE'?
                                            <span className='text-danger me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                        : match.MatchStatusTitle==='PENDING'?
                                            <span className='text-warning me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                        : match.MatchStatusTitle==='SETTLED'?
                                            <span className='text-dark me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                        :
                                            <span className='text-muted me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                        }
                                        {match.players} Players
                                    </p>
                                    <p className='mb-0 size-12 text-dark fw-bold'>{match.matchRef} <span className='mx-2'>|</span>Ksh. {match.winAmt}</p>
                                </div>
                                <div className="col align-self-center text-end">
                                    <p className="text-secondary text-muted size-10 mb-1">{formatPeriodDate(match.matchStart)}</p>
                                    {match.MatchStatusTitle==='LIVE'?
                                        <p className='mb-0 size-12 fw-bold text-dark'><Timer initialDateTime={match.matchStart} /></p>
                                    :
                                        <p className='mb-0 size-12 fw-bold text-dark'>{calculateDurationInHHMMSS(match.matchStart, match.matchEnd)}</p>
                                    }
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div className='mb-3 text-center'>
                    <img src="pacxos.svg" height='50px' alt />
                </div>
            </div>
        </>
    );
}

export default Dashboard;